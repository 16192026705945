import React, { createContext, useContext, useEffect, useState } from 'react'
import { createStorefrontApiClient } from '@shopify/storefront-api-client'
import {
  GRAPHQL_PRODUCT_FIELDS,
  GRAPHQL_PRODUCT_VARIANTS,
  GRAPHQL_CART_FIELDS,
  GRAPHQL_COLLECTION_PRODUCT_FIELDS
} from '../queries/shopify'
import { useLocalStorageV2 } from '../hooks/useLocalStorage'

const CART_ID_KEY = `bard_cart_id_v2`
// const COUNTRY_KEY = `bard_country_code_v1`

const client = createStorefrontApiClient({
  storeDomain: process.env.GATSBY_SHOPIFY_STORE_URL,
  apiVersion: process.env.GATSBY_SHOPIFY_API_VERSION || '2025-01',
  publicAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN
})

export const StoreContext = createContext({})

export function useStoreContext() {
  return useContext(StoreContext)
}

async function fetchProductByHandle({ handle }) {
  if (!handle) return null
  const query = `{
    product(handle: "${handle}") {
      ${GRAPHQL_PRODUCT_FIELDS}
      ${GRAPHQL_PRODUCT_VARIANTS}
    }
  }`
  return await client.request(query)
}

async function fetchProductsByIds({ ids = [] }) {
  if (!ids) return null
  const query = `
    query Products($ids: [ID!]!) {
      nodes(ids: $ids) {
        ... on Product {
          ${GRAPHQL_PRODUCT_FIELDS}
        }
      }
    }
  `
  return await client.request(query, {
    variables: {
      ids
    }
  })
}

async function fetchCollectionByHandle({
  handle,
  country = 'GB',
  sortKey,
  reverse
}) {
  const query = `
    query Collection($country: CountryCode, $handle: String!, $sortKey: ProductCollectionSortKeys, $reverse: Boolean) @inContext(country: $country) {
      collection(handle: $handle) {
        products(first: 250, sortKey: $sortKey, reverse: $reverse) {
          nodes {
            ${GRAPHQL_COLLECTION_PRODUCT_FIELDS}
          }
        }
      }
    }
  `
  return await client.request(query, {
    variables: {
      handle,
      country,
      sortKey,
      reverse
    }
  })
}

async function fetchProductRecommendations({ productId }) {
  const query = `
    query ProductRecommendations($productId: ID!) {
      productRecommendations(productId: $productId) {
        ${GRAPHQL_PRODUCT_FIELDS}
        ${GRAPHQL_COLLECTION_PRODUCT_FIELDS}
        productTitle: metafield(key: "product_title", namespace: "custom") {
          value
        }
      }
    }
  `
  return await client.request(query, {
    variables: {
      productId
    }
  })
}

async function fetchCartById(id) {
  if (!id) return null

  const query = `
    {
      cart (
        id: "${id}"
      ) {
        ${GRAPHQL_CART_FIELDS}
      }
    }
  `
  const { data, errors } = await client.request(query)

  if (errors) {
    console.error('cart response error', errors)
    return null
  } else {
    // console.log('fetchCartById data', data)
    return data
  }
}

async function createCart() {
  const operation = `mutation createCart {
    cartCreate {
      cart {
        ${GRAPHQL_CART_FIELDS}
      }
    }
  }`

  const { data, errors } = await client.request(operation)

  if (errors) {
    console.error('createCart error', errors)
    return null
  } else {
    console.log('createCart data', data)
    return data.cartCreate.cart
  }
}

async function handleAddLineItem(cartId = '', lines = []) {
  /* Line item example:
    const lineItemExample = {
      merchandiseId: variantId,
      quantity: 1,
      attributes: [
        key: "gift-wrap",
        value: "yes"
      ]
    }
  */
  const operation = `
    mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
      cartLinesAdd(cartId: $cartId, lines: $lines) {
        cart {
          ${GRAPHQL_CART_FIELDS}
        }
        userErrors {
          field
          message
        }
      }
    }
  `
  const { data, errors } = await client.request(operation, {
    variables: {
      lines: lines,
      cartId: cartId
    }
  })

  if (errors) {
    console.error('addLineItem error', errors)
    return null
  } else {
    return data.cartLinesAdd
  }
}

async function updateCartLineItem(cartId = '', lines = []) {
  /**
   * Line item example:
   * const lineItemExample = {
   *  id: lineItemId,
   *  quantity: 0
   * }
   */

  const operation = `
    mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
      cartLinesUpdate(cartId: $cartId, lines: $lines) {
        cart {
          ${GRAPHQL_CART_FIELDS}
        }
        userErrors {
          field
          message
        }
      }
    }
  `
  const { data, errors } = await client.request(operation, {
    variables: {
      cartId: cartId,
      lines: lines
    }
  })

  if (errors) {
    console.error('cartLinesUpdate error', errors)
    return null
  } else {
    return data.cartLinesUpdate
  }
}

export default function StoreProvider({ children }) {
  const localStorageCart = useLocalStorageV2(CART_ID_KEY, null)
  const cartInitialized = localStorageCart?.initialized
  const cartId = localStorageCart?.value

  const [cart, setCart] = useState(null)
  const [loading, setLoading] = useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    // Handle response
    function handleExisitingCart(cart) {
      setCart(cart)
    }

    function handleNewCart(cart) {
      // console.log("New cart created:", cart)
      localStorageCart.setValue(cart.id)
      setCart(cart)
    }

    // Fetch product by ID
    async function updateCart() {
      if (cartInitialized && !cart) {
        if (cartId) {
          console.log('updating cart...')
          setLoading(true)
          setError(null)

          try {
            const exisitingCart = await fetchCartById(cartId)
            if (exisitingCart) {
              handleExisitingCart(exisitingCart.cart)
            } else {
              const newCart = await createCart()
              if (newCart) {
                handleNewCart(newCart)
              }
            }
          } catch (err) {
            setError(err)
            console.error('Error fetching cart:', err)
          } finally {
            setLoading(false)
          }
        } else {
          // console.warn("No cartId found in localStorage")
          // console.log(localStorageCart)
          try {
            const newCart = await createCart()
            if (newCart) {
              handleNewCart(newCart)
            }
          } catch (err) {
            setError(err)
            console.error('Error creating cart:', err)
          } finally {
            setLoading(false)
          }
        }
      } else {
        // localStorageCart problem?
      }
    }

    // Init
    updateCart()
  }, [cartId, cartInitialized, cart])

  // console.log('cart', cart)

  const addLineItem = async (variantId, callback) => {
    setLoading(true)
    try {
      if (cartId) {
        const lineItem = {
          merchandiseId: variantId,
          quantity: parseInt(1, 10),
          attributes: []
        }

        const updatedCart = await handleAddLineItem(cartId, [lineItem])
        if (updatedCart) {
          if (callback) callback(updatedCart.cart)
          setCart(updatedCart.cart)
          return
        } else {
          console.error('Add to cart error:', updatedCart)
          return
        }
      }
    } catch (error) {
      console.error('Add to cart error:', error)
      return false
    } finally {
      setLoading(false)
      setDidJustAddToCart(true)
      setTimeout(() => setDidJustAddToCart(false), 500)
      return
    }
  }

  const updateLineItem = async (lineItemID, quantity, callback) => {
    setLoading(true)

    try {
      const updateCart = await updateCartLineItem(cartId, [
        { id: lineItemID, quantity: parseInt(quantity, 10) }
      ])
      if (updateCart) {
        setCart(updateCart.cart)
        if (callback) callback(updateCart.cart)
        return
      }
    } catch (error) {
      console.error('updateLineItem error:', error)
      callback && callback(null, error)
      return false
    } finally {
      setLoading(false)
      setDidJustAddToCart(true)
      setTimeout(() => setDidJustAddToCart(false), 500)
      return
    }
  }

  const removeLineItem = (lineItemId) => {
    updateLineItem(lineItemId, 0)
  }

  // Fetch collection by handle
  async function fetchCollection({ handle, country = 'GB', sortKey, reverse }) {
    if (!handle) return

    // setLoading(true)
    setError(null)

    try {
      const response = await fetchCollectionByHandle({
        handle,
        country,
        sortKey,
        reverse
      })
      return response
    } catch (err) {
      setError(err)
      console.error('Error fetching collection:', err)
    } finally {
      // setLoading(false)
    }
  }

  // Fetch product by handle
  async function fetchProduct({ handle }) {
    if (!handle) return

    // setLoading(true)
    setError(null)

    try {
      const response = await fetchProductByHandle({ handle })
      return response
    } catch (err) {
      setError(err)
      console.error('Error fetching product:', err)
    } finally {
      // setLoading(false)
    }
  }

  // fetch many products by ids
  async function fetchProducts({ ids = [] }) {
    if (!ids) return null
    setError(null)

    try {
      const response = await fetchProductsByIds({ ids })
      return response
    } catch (err) {
      setError(err)
      console.error('Error fetching products:', err)
    } finally {
      // setLoading(false)
    }
  }

  // Fetch product recommendations
  async function fetchRecommendations({ productId }) {
    if (!productId) return
    setError(null)

    try {
      const response = await fetchProductRecommendations({ productId })
      return response
    } catch (err) {
      setError(err)
      console.error('Error fetching recommendations:', err)
    }
  }

  return (
    <StoreContext.Provider
      value={{
        cart,
        addLineItem,
        updateLineItem,
        removeLineItem,
        loading,
        didJustAddToCart,
        localCountryCode: 'GB', // deprecated
        // resolveProductData, // deprecated
        fetchCollection,
        // new
        fetchProduct,
        fetchProducts,
        fetchRecommendations
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
