// import React from 'react'
// import StoreProvider from './src/wrappers/store'

// export const wrapRootElement = ({ element }) => (
//   <StoreProvider>{element}</StoreProvider>
// )

import React from 'react'
import Root from './src/wrappers/root'
import Store from './src/wrappers/store'
import { trackPageView } from './src/components/analytics'
// const isProduction = true // process.env.NODE_ENV !== 'development'

// export const onRouteUpdate = ({ location }) => {
// 	setTimeout(() => {
// 		trackPageView(location)
// 	}, 100)

// 	return true
// }

export const onRouteUpdate = ({ location }) => {
  setTimeout(() => {
    trackPageView(location)
  }, 100)

  return true
}

export const wrapRootElement = ({ element }) => {
  return (
    <Root>
      <Store>{element}</Store>
    </Root>
  )
}
