const GRAPHQL_PRODUCT_VARIANTS = `
  variants(first: 10) {
    nodes {
      availableForSale
      price: priceV2 {
        amount
        currencyCode
      }
      currentlyNotInStock
      compareAtPrice: compareAtPriceV2 {
        amount
        currencyCode
      }
      id
      storefrontId: id
      selectedOptions {
        name
        value
      }
      sku
    }
  }
`

const GRAPHQL_COLLECTION_PRODUCT_FIELDS = `
  id
  priceRange {
    maxVariantPrice {
      amount
      currencyCode
    }
    minVariantPrice {
      amount
      currencyCode
    }
  }
  compareAtPriceRange {
    maxVariantPrice {
      amount
      currencyCode
    }
    minVariantPrice {
      amount
      currencyCode
    }
  }
  availableForSale
`

const GRAPHQL_PRODUCT_FIELDS = `
  title
  handle
  id
  options(first: 3) {
    values
    name
  }
  media(first: 8) {
    nodes {
      previewImage {
        src
      }
    }
  }
  availableForSale
`

const GRAPHQL_CART_FIELDS = `
	id
	checkoutUrl
	cost {
		totalAmount {
			amount
			currencyCode
		}
	}
  totalQuantity
	lines(first: 200) {
		edges {
			node {
				id
				quantity
				merchandise {
					... on ProductVariant {
						id
						price {
							amount
							currencyCode
						}
            compareAtPrice {
              amount
              currencyCode
            }
						product {
							title
							handle
							vendor
							featuredImage {
								url
								width
								height
							}
						}
						selectedOptions {
							name
							value
						}
            quantityAvailable
					}
				}
        attributes {
          key
          value
        }
			}
		}
	}
`

const GRAPHQL_COLLECTION_FIELDS = `
  title
  handle
  products(first: 250) {
    nodes {
      id
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      compareAtPriceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      availableForSale
    }
  }
`

export {
  GRAPHQL_PRODUCT_FIELDS,
  GRAPHQL_PRODUCT_VARIANTS,
  GRAPHQL_CART_FIELDS,
  GRAPHQL_COLLECTION_FIELDS,
  GRAPHQL_COLLECTION_PRODUCT_FIELDS
}
